import "./Main.scss";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { API } from "../../utility/api";
// images
import cart from "../../assets/add-to-cart.png";
import cartSvg from "../../assets/cart-plus-svgrepo-com.svg";
// slider
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ConstructionProducts from "../../utility/data";
import { useMemo } from "react";
import { ToastContainer, toast } from "react-toastify";

export default function Main({ setOrder }) {
  const cleanHTML = useMemo(
    () => (input) => {
      let tempDiv = document.createElement("div");
      tempDiv.innerHTML = input;
      return tempDiv.textContent || tempDiv.innerText || "";
    },
    []
  );

  const { t, i18n } = useTranslation();
  const [, setError] = useState(null);
  const [data, setData] = useState([]);
  const [newData, setNewData] = useState([]);
  const [discountProducts, setDiscountProducts] = useState([]);

  const handleAddToCart = () => {
    toast.success("Product added to cart!", {
      position: "top-center",
      autoClose: 2000, // 2 soniyada yo'qoladi
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
    });
  };

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    //   autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 768, // Ekran kengligi 1024px dan kichik bo'lganda
        settings: {
          slidesToShow: 2, // 2 ta slayd ko'rsatiladi
          slidesToScroll: 1, // 1 ta slayd o'tadi
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    Promise.all([
      API.get("/product/get-top-products"),
      API.get("/product/get-new-products"),
      API.get("/product/get-products-discount"),
    ])
      .then(([topRes, newRes, discountRes]) => {
        setData(topRes.data.data);
        setNewData(newRes.data.data);
        setDiscountProducts(discountRes.data.data);
      })
      .catch((error) => setError(error));
  }, []);

  const addToCart = (id, productList) => {
    const oldCart = JSON.parse(localStorage.getItem("cart")) || [];
    const existingProduct = oldCart.find((item) => item.id === id);

    if (existingProduct) {
      existingProduct.qty += 1;
    } else {
      const newProduct = productList.find((item) => item.id === id);
      if (newProduct) {
        oldCart.push({ ...newProduct, qty: 1 });
      }
    }
    setOrder([...oldCart]);
    localStorage.setItem("cart", JSON.stringify(oldCart));

    handleAddToCart();
  };

  return (
    <div className="container">
      <main className="main mt-10">
        <h1 className="font-bold text-[30px] text-center">{t("mainTitle1")}</h1>
        <ul className="main__list block mb-10">
          <Slider {...settings}>
            {data && data.length > 0
              ? data?.map((item) => (
                  <li
                    key={item?.id}
                    className="main__item max-w-full  p-4 border-solid border-2 rounded mt-5 hover:shadow-lg bg-blue-700 text-white"
                  >
                    <span className="main__span p-2 bg-red-600 absolute rounded-lg text-white font-bold">
                      {t("mainTitle7")}
                    </span>
                    <img
                      className="mb-4 rounded-lg"
                      src={item?.image[0]}
                      width={300}
                      height={250}
                      alt=""
                    />
                    <h2 className="mb-2 font-bold text-[25px]">
                      {item?.[`name_${i18n.language}`]}
                    </h2>
                    <p className="text-ellipsis w-60 ">
                      {cleanHTML(item?.[`description_${i18n.language}`])}
                    </p>
                    {item?.discount ? (
                      <>
                        <span className="block mt-4 mb-1">{item.discount}</span>
                        <span className="opacity-[0.5] block mb-1 line-through text-[12px]">
                          {item.price}
                        </span>
                      </>
                    ) : (
                      <span className="block mt-4 mb-1">{item.price}</span>
                    )}
                    <div>
                      (<b>{t("mainTitle4")}:</b> {item?.stock}{" "}
                      {item?.[`unit_${i18n.language}`]})
                    </div>
                    <div className="main__wrapp flex items-center">
                      <Link
                        className="main__link p-2 rounded-lg mt-4 bg-sky-900 text-white font-bold"
                        to={`/order/${item.id}`}
                      >
                        {t("mainTitle8")}
                      </Link>
                      <button
                        onClick={() => addToCart(item.id, data)}
                        className="main__cart-img ml-20 mt-4 border-2 border-solid border-white p-1 rounded-lg hover:cursor-pointer"
                      >
                        <img src={cartSvg} width={30} height={30} alt="cart" />
                      </button>
                    </div>
                  </li>
                ))
              : ConstructionProducts.map((item) => (
                  <li
                    key={item.id}
                    className="main__item max-w-full p-4 border-solid border-2 rounded mt-5 hover:shadow-lg bg-blue-700 text-white"
                  >
                    <span className="main__span p-2 bg-red-600 absolute rounded-lg text-white font-bold">
                      {t("mainTitle7")}
                    </span>
                    <img
                      className="mb-4 w-full h-[200px] object-cover rounded-lg"
                      src={item?.image}
                      width={300}
                      height={250}
                      alt=""
                    />
                    <h2 className="mb-2 font-bold text-[25px]">
                      {item?.[`name`]}
                    </h2>
                    <p className="text-ellipsis max-w-60">
                      {cleanHTML(item?.[`description`])}
                    </p>
                    {item?.discount ? (
                      <>
                        <span className="block mt-4 mb-1">{item.discount}</span>
                        <span className="opacity-[0.5] block mb-1 line-through text-[12px]">
                          {item.price}
                        </span>
                      </>
                    ) : (
                      <span className="block mt-4 mb-1">{item.price}</span>
                    )}

                    <div className="main__wrapp flex items-center">
                      <Link
                        className="main__link p-2 rounded-lg mt-4 bg-sky-900 text-white font-bold"
                        to={`/order/${item.id}`}
                      >
                        {t("mainTitle8")}
                      </Link>
                      <button
                        onClick={() => addToCart(item.id, data)}
                        className="main__cart-img ml-20 mt-4 border-2 border-solid border-white p-1 rounded-lg hover:cursor-pointer"
                      >
                        <img src={cartSvg} width={30} height={30} alt="cart" />
                      </button>
                    </div>
                  </li>
                ))}
          </Slider>
        </ul>
        <div className="main__wrapper">
          <h2 className="text-[30px] font-bold text-center">
            {t("mainTitle2")}
          </h2>
          <ul className="main__wrapper__list flex flex-wrap mb-20">
            {newData?.map((item) => {
              return (
                <li
                  id="cars"
                  key={item?.id}
                  className="main__wrapper__item w-[350px] p-4 border-solid border-2 rounded mt-5 hover:shadow-lg mr-10"
                >
                  <span className="main__wrapper__span p-2 bg-red-600 absolute rounded-lg text-white font-bold">
                    {t("mainTitle3")}
                  </span>
                  <img
                    className="main__wrapper__img mb-4 rounded-lg"
                    src={item?.image[0]}
                    width={300}
                    height={200}
                    alt=""
                  />
                  <h2 className="mb-2 font-bold text-[25px]">
                    {item?.[`name_${i18n.language}`]}
                  </h2>
                  <p className="main__wrapper__text text-ellipsis w-60 ">
                    {cleanHTML(item?.[`description_${i18n.language}`])}
                  </p>
                  {item?.discount ? (
                    <>
                      <span className="block mt-4 mb-1">{item.discount}</span>
                      <span className="opacity-[0.5] block mb-1 line-through text-[12px]">
                        {item.price}
                      </span>
                    </>
                  ) : (
                    <span className="block mt-4 mb-1">{item.price}</span>
                  )}
                  <div className="">
                    (<b>{t("mainTitle4")}:</b> {item?.stock}{" "}
                    {item?.[`unit_${i18n.language}`]} )
                  </div>
                  <div className="main__wrapper__wrapp flex items-center ">
                    <Link
                      className="main__wrapper__link p-2 rounded-lg  mt-4 bg-sky-900 text-white text-center font-bold"
                      to={`/order/${item.id}`}
                    >
                      {t("mainTitle8")}
                    </Link>
                    <button
                      onClick={() => addToCart(item.id, newData)}
                      className="main__wrapper__cart-img ml-20 mt-4 border-2 border-solid border-black p-1 rounded-lg hover:cursor-pointer"
                    >
                      <img
                        className=""
                        src={cart}
                        width={30}
                        height={30}
                        alt="cart"
                      />
                    </button>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>

        <div className="main__wrapp">
          <h2 className="text-[30px] font-bold text-center">
            {t("mainTitle5")}
          </h2>
          <ul className="main__wrapp__list flex flex-wrap mb-20">
            {discountProducts?.map((item) => {
              return (
                <li
                  id="cars"
                  key={item?.id}
                  className="main__wrapp__item w-[320px] p-4 border-solid border-2 rounded mt-5 hover:shadow-lg mr-10"
                >
                  <span className="main__wrapp__span p-2 bg-red-600 absolute  rounded-lg text-white font-bold">
                    {t("mainTitle6")}
                  </span>
                  <img
                    className="main__wrapp__img mb-4 rounded-lg"
                    src={item?.image[0]}
                    width={300}
                    height={200}
                    alt=""
                  />
                  <h2 className="mb-2 font-bold text-[25px]">
                    {item?.[`name_${i18n.language}`]}
                  </h2>
                  <p className="main__wrapp__text text-ellipsis w-60 ">
                    {cleanHTML(item?.[`description_${i18n.language}`])}
                  </p>
                  {item?.discount ? (
                    <>
                      <span className="block mt-4 mb-1">{item.discount}</span>
                      <span className="opacity-[0.5] block mb-1 line-through text-[12px]">
                        {item.price}
                      </span>
                    </>
                  ) : (
                    <span className="block mt-4 mb-1">{item.price}</span>
                  )}
                  <div className="">
                    (<b>{t("mainTitle4")}:</b> {item?.stock}{" "}
                    {item?.[`unit_${i18n.language}`]} )
                  </div>
                  <div className="main__wrapp__wrapp flex items-center">
                    <Link
                      className="main__wrapp__link w-40 p-2 rounded-lg  mt-4 bg-sky-900 text-white font-bold"
                      to={`/order/${item?.id}`}
                    >
                      {t("mainTitle8")}
                    </Link>
                    <button
                      onClick={() => addToCart(item.id, discountProducts)}
                      className="main__wrapp__cart-img ml-20 mt-4 border-2 border-solid border-black p-1 rounded-lg hover:cursor-pointer"
                    >
                      <img
                        className=""
                        src={cart}
                        width={30}
                        height={30}
                        alt="cart"
                      />
                    </button>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
        <ToastContainer />
      </main>
    </div>
  );
}
