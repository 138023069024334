import "./products.scss";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { API } from "../../utility/api";
import { ToastContainer, toast } from "react-toastify";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import cart from "../../assets/add-to-cart.png";

export default function Products() {
  const { t, i18n } = useTranslation();
  const [products, setProducts] = useState([]);
  const [category, setCategory] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [currentCategory, setCurrentCategory] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 3;
  const [order] = useState(
    localStorage.getItem("cart") ? JSON.parse(localStorage.getItem("cart")) : []
  );

  useEffect(() => {
    API.get("/product/get-products").then(({ data }) => setProducts(data.data));
    API.get("/categorie/get-categories").then(({ data }) =>
      setCategory(data.categories)
    );
  }, []);

  function cleanHTML(input) {
    let tempDiv = document.createElement("div");
    tempDiv.innerHTML = input;
    return tempDiv.textContent || tempDiv.innerText || "";
  }

  const handleAddToCart = (id) => {
    let cart = JSON.parse(localStorage.getItem("cart")) || [];
    let itemIndex = cart.findIndex((item) => item.id === id);

    if (itemIndex !== -1) {
      cart[itemIndex].qty += 1;
    } else {
      let product = products.find((p) => p.id === id);
      cart.push({ ...product, qty: 1 });
    }

    localStorage.setItem("cart", JSON.stringify(cart));
    toast.success("Product added to cart!", {
      autoClose: 2000,
      hideProgressBar: true,
    });
  };

  const changeCategory = (id) => {
    setCurrentCategory(id);
    setFilteredProducts(products.filter((item) => item.categoryId === id));
    setCurrentPage(1);
  };

  const displayedProducts = (
    filteredProducts.length > 0 ? filteredProducts : products
  ).slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  return (
    <>
      <Header order={order} />
      <section className="mt-20 min-h-[100vh]">
        <div className="container">
          <div className="products flex">
            <div className="products__left w-[400px]">
              <div className="flex pt-10 ">
                <Link className="opacity-[0.5]" to={"/"}>
                  {t("headerTitle1")}
                </Link>
                <span className="font-bold mr-2 ml-2"> {">"} </span>
                <Link className="" to={"/profile"}>
                  {t("categoryTitle2")}
                </Link>
              </div>
              <h1 className="products__heading font-bold text-[30px] pt-10">
                {t("categoryTitle1")}
              </h1>

              {category?.map((cat) => (
                <div key={cat.id} className="mt-5">
                  <h3
                    key={cat.id}
                    onClick={() => changeCategory(cat.id)}
                    className={`w-64 text-[20px] mb-4 cursor-pointer hover:text-blue-700 ${
                      currentCategory === cat.id
                        ? "text-blue-900 font-bold underline"
                        : ""
                    }`}
                  >
                    {cat[`name_${i18n.language}`]}
                  </h3>
                </div>
              ))}
            </div>
            <div className="products__right">
              <h2 id="products" className="font-bold text-[30px] pt-10">
                {t("productTitle3")}
              </h2>
              <ul className="products__list flex  mb-10">
                {displayedProducts?.map((item) => (
                  <li
                    id="cars"
                    key={item.id}
                    className="products__item w-[320px] p-5 border-solid border-2 rounded mt-5 mr-4 hover:shadow-lg"
                  >
                    <img
                      className="products__item__img mb-4 rounded-lg"
                      src={item.image[0]}
                      width={280}
                      height={250}
                      alt=""
                    />
                    <h2 className="mb-2 font-bold text-[25px]">
                      {item?.[`name_${i18n.language}`]}
                    </h2>
                    <p className="main__text text-ellipsis w-60">
                      {cleanHTML(item?.[`description_${i18n.language}`])}
                    </p>
                    <span className="block mt-4 mb-1">{item.discount}</span>
                    <span className="opacity-[0.5] block mb-1 line-through text-[12px]">
                      {item.price}
                    </span>
                    <div className="">
                      (<b>{t("mainTitle4")}:</b> {item.stock})
                    </div>
                    <div className="main__wrapp flex items-center">
                      <Link
                        className=" max-w-40 p-2 mt-5 rounded-lg bg-sky-900 text-white font-bold"
                        to={`/order/${item.id}`}
                      >
                        Batafsil ma'lumot
                      </Link>
                      <button
                        onClick={() => handleAddToCart(`${item.id}`)}
                        className="main__cart-img ml-20 border-2 border-solid border-black p-1 rounded-lg hover:cursor-pointer"
                      >
                        <img
                          className=""
                          src={cart}
                          width={30}
                          height={30}
                          alt="cart"
                        />
                      </button>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
            <ToastContainer />
          </div>
          <div className="text-center mb-10">
            {[
              ...Array(
                Math.ceil(
                  (filteredProducts.length || products.length) / itemsPerPage
                )
              ).keys(),
            ].map((num) => (
              <button
                key={num + 1}
                onClick={() => setCurrentPage(num + 1)}
                className={`border-2 p-2 rounded-lg mr-2 ${
                  currentPage === num + 1 ? "bg-blue-500 text-white" : ""
                }`}
              >
                {num + 1}
              </button>
            ))}
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
