import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(LanguageDetector)
  .init({
    debug:true,
    lng:"en",
    resources: {
        en: {
            translation: {
                headerTitle1:"Home",
                headerTitle2:"About Us",
                headerTitle3:"Products",
                headerTitle4:"Contact",
                headerTitle5:"LOG OUT",
                headerTitle6:"Cart",
                headerTitle7:"Profile",
                headerTitle8:"Login",
                heroTitle1:"Colony construction products",
                heroTitle2:"Place an order",
                heroTitle3:"On our site you will find a wide selection of quality and affordable products. Browse the latest and most popular products in each category and order them easily. Find the product you need and enjoy a great shopping experience!",
                mainTitle1:"Top products",
                mainTitle2:"New products",
                mainTitle3:"NEW",
                mainTitle4:"stock",
                mainTitle5:"Discount products",
                mainTitle6:"DISCOUNT",
                mainTitle7:"TOP",
                mainTitle8:"Learn more",
                footerTitle1:"Contacts",
                footerTitle2:"Navoi region, Kiziltepa district, Miltiqchi MFY, Sharq street, 1 house",
                contactTitle1:"Ask us about",
                contactTitle2:"Different quality products",
                contactTitle3:"Home delivery services",
                contactTitle4:"Long term contract",
                contactTitle5:"Name",
                contactTitle6:"Email",
                contactTitle7:"Phone",
                contactTitle8:"Message",
                contactTitle9:"Submit",
                pageAbout:"About Us",
                aboutTitle2:"This website aims to present and sell quality products made by convicts in the colony to the general public. Our goal is to provide vocational training to convicts, to provide them with the opportunity to develop work skills and contribute to society through their work.",
                pageContact:"Contacts",
                productTitle1:"Construction products",
                productTitle2:"School equipment",
                productTitle3:"All products",
                productTitle4:"stock",
                profileTitle1:"Profile",
                profileTitle2:"Personal data",
                profileTitle3:"Orders",
                profileTitle4:"My orders",
                profileTitle5:"Full name:",
                profileTitle6:"Address:",
                profileTitle7:"Year of birth:",
                profileTitle8:"Phone:",
                profileMessages:"Messages",
                profilePayments:"Payments",
                profileNotification:"Notifications",
                profileOrder1:"End date of the contract",
                profileOrder2:"Contract file",
                profileOrder3:"Shipping Address",
                profileOrder4:"Delivery service",
                profileOrder5:"Total price",
                profileOrder6:"More",
                orderId1:"Upload payment details",
                orderId2:"Order details:",
                orderId3:"Customer's products",
                orderId4:"Ordering information:",
                orderId5:"Payment amount",
                orderId6:"Payment date",
                orderId7:"Payment cheque",
                orderId8:"Contract duration:",
                orderId9:"Payment duration:",
                orderId10:"Contract duration:",
                orderId11:"Deliviry time:",
                orderId12:"Order Status:",
                orderId13:"Delivery:",
                orderId14:"Shipping Address:",
                orderId15:"Customer ID number:",
                orderId16:"Customer's surname:",
                orderId17:"Customer's date of birth:",
                orderId18:"Customer's phone number:",
                orderId19:"Customer's address:",
                orderId20:"Total amount:",
                orderId21:"Amount Paid:",
                orderId22:"Payment percentage:",
                orderId23:"Delivery File:",
                orderId24:"Go to file",
                orderId25:"Information not available",
                orderId26:"Product Name:",
                orderId27:"Product description:",
                orderId28:"Product price:",
                orderId29:"Old price of the product:",
                orderId30:"In the warehouse:",
                orderId31:"Shipping amount:",
                cartTitle1:"Product in the cart:",
                cartTitle2:"Your order",
                cartTitle3:"Products",
                cartTitle4:"Total:",
                cartTitle5:"Within delivery:",
                cartTitle6:"Go to formalization",
                cartTitle7:"delete",
                categoryTitle1:"All categories",
                categoryTitle2:"Category",
                addressTitle1:"Order delivery address",
                addressTitle2:"Please enter the address in detail and correctly",
                addressTitle3:"Select",
                addressTitle4:"Enter your address",
                addressTitle5:"Enter your phone number",
                addressTitle6:"Get a contract",
            },
        },
        ru: {
            translation: {
                headerTitle1:"Главная",
                headerTitle2:"О нас",
                headerTitle3:"Продукты",
                headerTitle4:"Контакт",
                headerTitle5:"ВЫЙТИ",
                headerTitle6:"Корзина",
                headerTitle7:"Профиль",
                headerTitle8:"Вход",
                heroTitle1:"Строительная продукция колонии",
                heroTitle2:"Разместить заказ",
                heroTitle3:"На нашем сайте вы найдете широкий выбор качественной и доступной продукции. Просматривайте новейшие и самые популярные товары в каждой категории и легко заказывайте их. Найдите нужный товар и наслаждайтесь покупками!",
                mainTitle1:"Лучшие продукты",
                mainTitle2:"Новые продукты",
                mainTitle3:"НОВЫЙ",
                mainTitle4:"остаток",
                mainTitle5:"Товары со скидкой",
                mainTitle6:"СКИДКА",
                mainTitle7:"ВЕРШИНА",
                mainTitle8:"Yзнать больше",
                footerTitle1:"Контакты",
                footerTitle2:"Навоийская область, Кизилтепинский район, МФМ Милтикчи, улица Шарка, 1 дом",
                contactTitle1:"Спросите нас о",
                contactTitle2:"Различном качестве продукции",
                contactTitle3:"Услуги доставки на дом",
                contactTitle4:"Долгосрочный контракт",
                contactTitle5:"Имя",
                contactTitle6:"Электронная почта",
                contactTitle7:"Телефон",
                contactTitle8:"Сообщение",
                contactTitle9:"Отправить",
                pageAbout:"О нас",
                aboutTitle2:"Целью данного сайта является представление и продажа широкой публике качественной продукции, производимой осужденными в колонии. Наша цель – обеспечить профессиональную подготовку осужденных, предоставить им возможность развивать трудовые навыки и вносить свой вклад в жизнь общества своим трудом.",
                pageContact:"Контакт",
                productTitle1:"Строительная продукция",
                productTitle2:"Школьное оборудование",
                productTitle3:"Все продукты",
                productTitle4:"запас",
                profileTitle1:"Профиль",
                profileTitle2:"Личные данные",
                profileTitle3:"Заказы",
                profileTitle4:"Мои заказы",
                profileTitle5:"ФИО:",
                profileTitle6:"Адрес:",
                profileTitle7:"Год рождения:",
                profileTitle8:"Телефон:",
                profileMessages:"Сообщения",
                profilePayments:"Платежи",
                profileNotification:"Уведомления",
                profileOrder1:"Дата окончания контракта",
                profileOrder2:"Файл контракта",
                profileOrder3:"Адрес доставки",
                profileOrder4:"Служба доставки",
                profileOrder5:"Общая стоимость",
                profileOrder6:"Более",
                orderId1:"Загрузите данные платежа",
                orderId2:"Детали заказа:",
                orderId3:"Продукция клиента",
                orderId4:"Информация для заказа:",
                orderId5:"Сумма платежа",
                orderId6:"Сумма дата",
                orderId7:"Сумма чек",
                orderId8:"Срок действия контракта:",
                orderId9:"Срок оплаты:",
                orderId10:"Срок контракта:",
                orderId11:"Срок поставки:",
                orderId12:"Статус заказа:",
                orderId13:"Доставка:",
                orderId14:"Адрес доставки:",
                orderId15:"Идентификационный номер клиента:",
                orderId16:"Фамилия клиента:",
                orderId17:"Дата рождения клиента:",
                orderId18:"Телефон клиента:",
                orderId19:"Адрес клиента:",
                orderId20:"Группа ядов:",
                orderId21:"Краткая сумма:",
                orderId22:"Процент оплаты:",
                orderId23:"Файл доставки:",
                orderId24:"Перейти к файлу",
                orderId25:"Информация недоступна",
                orderId26:"Название продукта:",
                orderId27:"Описание продукта:",
                orderId28:"Цена продукта:",
                orderId29:"Старая цена товара:",
                orderId30:"На складе:",
                orderId31:"Сумма доставки:",
                cartTitle1:"Tовар в корзине:",
                cartTitle2:"Ваш заказ",
                cartTitle3:"Продукты",
                cartTitle4:"Итого:",
                cartTitle5:"В пределах доставки:",
                cartTitle6:"Перейти к формализации",
                cartTitle7:"удалить",
                categoryTitle1:"Все категории",
                categoryTitle2:"Kатегории",
                addressTitle1:"Адрес доставки заказа",
                addressTitle2:"Пожалуйста, введите адрес подробно и правильно",
                addressTitle3:"Выбрать",
                addressTitle4:"Введите ваш адрес",
                addressTitle5:"Введите ваш номер телефона",
                addressTitle6:"Получить контракт",
            },
        },
        uz: {
            translation: {
                headerTitle1:"Bosh sahifa",
                headerTitle2:"Biz haqimizda",
                headerTitle3:"Maxsulotlar",
                headerTitle4:"Aloqalar",
                headerTitle5:"CHIQISH",
                headerTitle6:"Savatcha",
                headerTitle7:"Profil",
                headerTitle8:"Kirish",
                heroTitle1:"Koloniya qurilish maxsulotlari",
                heroTitle2:"Buyurtma berish",
                heroTitle3:"Bizning saytimizda siz sifatli va hamyonbop mahsulotlarning keng tanlovini topasiz. Har bir kategoriya bo‘yicha eng so‘nggi va ommabop tovarlarni ko‘zdan kechiring va ularni osonlik bilan buyurtma qiling.Siz kerak bo'lgan mahsulotni qidirib toping va ajoyib xarid tajribasidan bahramand bo‘ling!",
                mainTitle1:"Eng yaxshi mahsulotlar",
                mainTitle2:"Yangi maxsulotlar",
                mainTitle3:"YANGI",
                mainTitle4:"qoldiq",
                mainTitle5:"Chegirmali mahsulotlar",
                mainTitle6:"CHEGIRMA",
                mainTitle7:"TOP",
                mainTitle8:"Batafsil ma'lumot",
                footerTitle1:"Kontaktlar",
                footerTitle2:"Navoiy viloyati, Qiziltepa tumani, Miltiqchi MFY, Sharq ko'chasi, 1-uy",
                contactTitle1:"Bizdan so'rang",
                contactTitle2:"Turli xil sifatli mahsulotlar",
                contactTitle3:"Uyga yetkazib berish xizmatlari",
                contactTitle4:"Uzoq muddatli shartnoma",
                contactTitle5:"Ism",
                contactTitle6:"Elektron pochta",
                contactTitle7:"Telefon",
                contactTitle8:"Xabar",
                contactTitle9:"Yuborish",
                pageAbout:"Biz haqimizda",
                aboutTitle2:"Ushbu veb-sayt koloniyadagi mahkumlar tomonidan ishlab chiqarilgan sifatli mahsulotlarni keng jamoatchilikka taqdim etish va sotishni maqsad qilgan. Bizning maqsadimiz - mahkumlarga hunar o'rgatish, ularga mehnat ko'nikmalarini rivojlantirish va o'z mehnatlari orqali ijtimoiy foyda keltirish imkoniyatini taqdim etishdir.",
                pageContact:"Biz bilan bog'laning",
                productTitle1:"Qurilish maxsulotlari",
                productTitle2:"Maktab jixozlari",
                productTitle3:"Barcha maxsulotlar",
                productTitle4:"qoldiq",
                profileTitle1:"Profil",
                profileTitle2:"Shaxsiy ma'lumot",
                profileTitle3:"Buyurtmalar",
                profileTitle4:"Mening buyurtmalarim",
                profileTitle5:"FISH:",
                profileTitle6:"Manzil:",
                profileTitle7:"Tug'ilgan yil:",
                profileTitle8:"Telfon:",
                profileMessages:"Xabarlar",
                profilePayments:"To'lovlar",
                profileNotification:"Bildirishnomalar",
                profileOrder1:"Shartnomaning tugash sanasi",
                profileOrder2:"Shartnoma fayli",
                profileOrder3:"Yetkazib berish manzili",
                profileOrder4:"Yetkazib berish xizmati",
                profileOrder5:"Umumiy narx",
                profileOrder6:"Batafsil",
                orderId1:"To'lov tafsilotlarini yuklash",
                orderId2:"Buyurtma tafsilotlari:",
                orderId3:"Buyurtmachining maxsulotlari",
                orderId4:"Buyurtma beruvchi ma'lumotlari:",
                orderId5:"To'lov miqdori",
                orderId6:"To'lov sanasi",
                orderId7:"To'lov cheki",
                orderId8:"Shartnoma muddati:",
                orderId9:"To'lov muddati:",
                orderId10:"Kontrakt muddati:",
                orderId11:"Yetkazib berish muddati",
                orderId12:"Buyurtma holati:",
                orderId13:"Yetkazib berish:",
                orderId14:"Yetkazib berish manzili:",
                orderId15:"Buyurtmachining ID raqami:",
                orderId16:"Buyurtmachining ism familyasi:",
                orderId17:"Buyurtmachining tug'ulgan sanasi:",
                orderId18:"Buyurtmachining telfon raqami:",
                orderId19:"Buyurtmachining manzili:",
                orderId20:"Jami summa:",
                orderId21:"To'langan summa:",
                orderId22:"To'lov foizi:",
                orderId23:"Yetkazib berish fayili:",
                orderId24:"Fayilga o'tish",
                orderId25:"Ma'lumot mavjud emas",
                orderId26:"Maxsulotning nomi:",
                orderId27:"Maxsulotning tavsifi:",
                orderId28:"Maxsulot narxi:",
                orderId29:"Maxsulotni eski narxi:",
                orderId30:"Omborxonada:",
                orderId31:"Yetkazib berish summasi:",
                cartTitle1:"Korzinkada maxsulot:", 
                cartTitle2:"Buyurtmangiz",
                cartTitle3:"Mahsulotlar",
                cartTitle4:"Jami:",
                cartTitle5:"Yetkazib berish ichida",
                cartTitle6:"Rasmiylashtirishga o'tish",
                cartTitle7:"o'chirish",
                categoryTitle1:"Barcha kategoriyalar",
                categoryTitle2:"Kategoriya",
                addressTitle1:"Buyurtma yetkaziladigan manzil",
                addressTitle2:"Iltimos manzilni batafsil va to'g'ri kiriting",
                addressTitle3:"Tanlang",
                addressTitle4:"Manzilingizni kiriting ",
                addressTitle5:"Telefon raqamingizni kiriting",
                addressTitle6:"Shartnoma olish",
            },
        },
    },
    
    
  });

export default i18n;